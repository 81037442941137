<template>
  <b-row
    v-if="acc.account"
    class="mt-1"
  >
    <b-col lg="4">
      <div class="d-flex">
        <h5>
          {{ $t('forex.balance') }}:
        </h5>
        <div class="margin-style">
          {{ acc.balance }}
        </div>
      </div>
    </b-col>
    <b-col lg="4">
      <div class="d-flex">
        <h5>
          {{ $t('forex.group') }}:
        </h5>
        <div class="margin-style">
          {{ acc.group }}
        </div>
      </div>
    </b-col>
    <b-col lg="4">
      <div class="d-flex">
        <h5>
          {{ $t('forex.platform') }}:
        </h5>
        <div class="margin-style">
          {{ acc.platform }}
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCol,
  BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
  },
  props: {
    acc: {
      required: true,
      type: Object,
    },
  },
}
</script>
<style scoped>
.margin-style{
    margin-left:10px;
}
</style>
